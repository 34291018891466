/* Custom range slider button
-------------------------------------------------- */

input[type=range].rangeSliderInput {
    height: 30px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type=range].rangeSliderInput:focus {
    outline: none;
}

input[type=range].rangeSliderInput::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #BFE4FF;
    border-radius: 5px;
    border: 0px solid #000000;
}

input[type=range].rangeSliderInput::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #0091FF;
    height: 22px;
    width: 22px;
    border-radius: 25px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
}

input[type=range].rangeSliderInput:focus::-webkit-slider-runnable-track {
    background: #BFE4FF;
}

input[type=range].rangeSliderInput::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #BFE4FF;
    border-radius: 5px;
    border: 0px solid #000000;
}

input[type=range].rangeSliderInput::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #0091FF;
    height: 22px;
    width: 22px;
    border-radius: 25px;
    background: #FFFAFA;
    cursor: pointer;
}

input[type=range].rangeSliderInput::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range].rangeSliderInput::-ms-fill-lower {
    background: #BFE4FF;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range].rangeSliderInput::-ms-fill-upper {
    background: #BFE4FF;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range].rangeSliderInput::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #0091FF;
    height: 22px;
    width: 22px;
    border-radius: 25px;
    background: #FFFAFA;
    cursor: pointer;
}

input[type=range].rangeSliderInput:focus::-ms-fill-lower {
    background: #BFE4FF;
}

input[type=range].rangeSliderInput:focus::-ms-fill-upper {
    background: #BFE4FF;
}
